import { Form } from "antd";
import { useState } from "react";

import { useApiErrorHandler } from "@hooks/error-handling/useApiErrorHandler";
import { useAsyncSelector } from "@hooks/useAsyncSelector";
import { userUriState } from "@states/auth.state";
import {
  isValidMobileNumberFromDBState,
  profileState,
  tanChannelState
} from "@states/profile.state";
import isApiErrorResponse from "smavesto.core/lib/utils/typeguards/isApiErrorResponse";
import type TanChannel from "smavesto.core/lib/types/dto/tan/TanChannel";
import smavestoCore from "@src/utils/services/SmavestoCoreClient";

export const useTanForm = (
  destroyTanModal: () => void,
  updateData: (
    auth: { userUri: string; tan: string; channel: TanChannel },
    destroyTanModal: () => void
  ) => Promise<void>
) => {
  const [form] = Form.useForm();

  const [handleApiError] = useApiErrorHandler();

  const [isSubmit, setIsSubmit] = useState(false);

  const [, tanChannel] = useAsyncSelector(tanChannelState("loggedInUser"));

  const [isGenerateTan, setIsGenerateTan] = useState(false);

  const [tanLoading, setTanLoading] = useState(false);

  const [tan, setTan] = useState("");

  const [, userUri] = useAsyncSelector(userUriState("loggedInUser"));

  const [, profile] = useAsyncSelector(profileState("loggedInUser"));

  const [, isPhoneNumberValid] = useAsyncSelector(
    isValidMobileNumberFromDBState("loggedInUser")
  );

  const tanDescription =
    tanChannel === "sms"
      ? `(SMS-TAN), den wir an die folgende Nummer schicken: ${
          profile?.mobile ?? ""
        }`
      : `(E-Mail-TAN), den wir an die folgende E-Mail-Adresse schicken: ${
          profile?.email ?? ""
        }`;

  const otherChannelName = tanChannel === "sms" ? `E-Mail-Tan` : `SMS-Tan`;

  const otherChannelIsAvailable = isPhoneNumberValid || tanChannel === "sms";

  const onGenerateTan = async () => {
    if (!userUri || !tanChannel) return;

    setTanLoading(true);
    const generateTanResult = await smavestoCore.services.twilio.generateTan2FA(
      tanChannel
    );

    if (isApiErrorResponse(generateTanResult)) {
      setIsGenerateTan(false);
      handleApiError(generateTanResult, "modal", "error");
    } else {
      setIsGenerateTan(true);
    }
    setTanLoading(false);
  };

  const onFinish = async () => {
    if (!userUri || !tanChannel) return;

    setIsSubmit(true);
    await updateData({ userUri, tan, channel: tanChannel }, destroyTanModal);

    setIsSubmit(false);
  };

  return {
    form,
    onFinish,
    isSubmit,
    tanDescription,
    tanLoading,
    isGenerateTan,
    onGenerateTan,
    tan,
    setTan,
    otherChannelName,
    otherChannelIsAvailable
  };
};
